// @flow

import React from 'react';
import { connect } from 'react-redux';
import { isMyPathVAStudy, isMyPathPeripartumVA } from '../../../lib/appMode';
import MainNavButton from '../../shared/nav/MainNavButton';
import {
  MarkdownBlock,
  Headings,
} from '../../shared/textComponents/TextComponents';
import Instruction from '../../shared/note/Instruction';
import { numOfQuestionToUnlockMainNavs } from '../../../reducers/surveyReducerMP';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import noteActions from '../../../actions/noteActions';
import type {
  State,
  MapStateToProps,
  SurveyStateMP,
  PageProps,
} from '../../../lib/types';
import '../../shared/main/MainPage.scss';
import './MainMP.scss';

type MainPropsMP = PageProps & {
  surveyStateMP: SurveyStateMP,
  visitedState: Array<boolean>,
  navigationVisibility: { visibility: boolean },
  setModalInvisible: () => void,
};

const computeVersionSpecificVariables = (surveyStateMP, navLabels) => {
  /* do we show all nav links? */
  let isShowingAllNavs = true;

  if (isMyPathVAStudy) {
    const isMainNavUnlocked = (arr: Array<null | number>): boolean => {
      if (numOfQuestionToUnlockMainNavs < 1) return true;
      if (arr[2] === 0) return true;
      if (
        arr[2] === 3 &&
        typeof arr[4] === 'number' &&
        typeof arr[5] === 'number'
      )
        return true;
      if (
        arr.filter(
          (v: undefined | number | null): boolean => typeof v === 'number'
        ).length >= numOfQuestionToUnlockMainNavs
      )
        return true;
      return false;
    };
    isShowingAllNavs = isMainNavUnlocked(surveyStateMP);
  }

  /* how do we match each link with their redux visited state? */
  const linkIndexes = navLabels.map((_v, i) => i);

  /* [Dev Note]: 
    for MyPath Postpartum VA, the client would like to swap the order of link #1 and link #4
    However, each link has an associated "visitedState" Redux state that is persisited in Local Storage using the original order,
    so we need to swap the indexes.
    We could have used CSS rule "flex-order" to visually adjust the link order, but that is an accessibility / 508 anti-pattern.
  */
  if (isMyPathPeripartumVA) {
    [linkIndexes[0], linkIndexes[3]] = [linkIndexes[3], linkIndexes[0]]; // swap in place using ES6 destructuring
  }

  return { isShowingAllNavs, linkIndexes };
};

class MainMP extends React.Component<MainPropsMP> {
  constructor(props: MainPropsMP) {
    super(props);
    this.headerRef = React.createRef();
    this.navRefs = props.lexicon
      .get('navs')
      .map((): Object => React.createRef());
  }

  componentDidMount() {
    const { visitedState, setModalInvisible } = this.props;
    if (visitedState.length === 0 && this.headerRef.currents) {
      this.headerRef.current.focus();
    } else if (this.navRefs[visitedState[visitedState.length - 1]]) {
      this.navRefs[visitedState[visitedState.length - 1]].current.focus();
    }
    setModalInvisible();
  }

  render() {
    const {
      lexicon,
      locale,
      visitedState,
      surveyStateMP,
      navLinks,
      isAnyModalVisible,
      isShowingSummaryReminder,
      hideSummaryReminder,
    } = this.props;

    const navLabels = lexicon.get('navs');

    const { isShowingAllNavs, linkIndexes } = computeVersionSpecificVariables(
      surveyStateMP,
      navLabels
    );

    return (
      <>
        <Headings
          externalHeading1Ref={this.headerRef}
          str={lexicon.get('headings')}
          ariaHidden={isAnyModalVisible}
        />
        <MarkdownBlock
          str={lexicon.get('paragraphs')}
          ariaHidden={isAnyModalVisible}
        />
        <div className="main-nav-buttons-container" role="menu">
          {navLabels.map((nav: string, i: number): React$Element<*> | null => {
            const index = linkIndexes[i];
            if (!isShowingAllNavs && index > 0) return null;

            return (
              <MainNavButton
                key={nav}
                isVisited={visitedState.includes(index)}
                isHidden={isAnyModalVisible}
                type="MP"
                // regular NavButton props below
                str={nav}
                to={`/${locale}/${navLinks[index]}`}
                index={index}
                linkRef={this.navRefs[index]}
              />
            );
          })}

          <Instruction
            header={lexicon.get('summary_reminder.header')}
            body={lexicon.get('summary_reminder.body')}
            visible={isShowingSummaryReminder}
            onCloseClick={hideSummaryReminder}
            isSummaryReminder
          />
        </div>
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<State> = ({
  visitedStateMP,
  surveyStateMP,
  isShowingSummaryReminder,
  isAnyModalVisible,
  userId,
}: State): Object => ({
  visitedState: visitedStateMP,
  surveyStateMP,
  isShowingSummaryReminder,
  isAnyModalVisible,
  userId,
});
const mapDispatchToProps = {
  hideSummaryReminder: (): {} => ({ type: 'SET_SUMMARY_REMINDER_HIDDEN' }),
  ...isAnyModalVisibleActions,
  ...noteActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMP);
